import { useContext, useState, useEffect } from "react";
// import {
//   sendComplexityAnnotations,
//   getLastComplexityAnnotationIndex,
// } from "../../api";
import PageContext from "../StatesManager";

import "./styles.scss";

export function ComplexityButtons({ setMRIndex }) {
  const {
    user,
    complexityAnnotation,
    datasetCategory,
    folderNameState,
    imageNamesOnFolderState,
    mrIndex,
    jobName,
    setSelectedComplexity,
    selectedComplexity,
  } = useContext(PageContext);

  // const [complexityState, setComplexityState] = useState();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (complexityState) {
  //       const response = await sendComplexityAnnotations(
  //         complexityState,
  //         user,
  //         datasetCategory,
  //         mrIndex,
  //         folderNameState,
  //         imageNamesOnFolderState[0],
  //         jobName
  //       );

  //       if (response) {
  //         const lastIndex = await getLastComplexityAnnotationIndex(
  //           user,
  //           datasetCategory,
  //           jobName
  //         );
  //         setComplexityState(null);
  //         setMRIndex(lastIndex);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [complexityState]);

  function sendAnnotations(complexity) {
    setSelectedComplexity(complexity);
  }

  const array1to9 = [...Array(9).keys()].map((x) => ++x);

  return (
    <div className="complexity-buttons">
      <div className="scores-1-to-9">
        {array1to9.map((value) => {
          return (
            <button
              style={{
                backgroundColor:
                  selectedComplexity === value ? "#00690e" : "#0563af",
              }}
              onClick={() => sendAnnotations(value)}
            >
              {value}
            </button>
          );
        })}
      </div>

      <div className="score-10">
        <button
          className={"last"}
          style={{
            backgroundColor: selectedComplexity === 10 ? "#00690e" : "#0563af",
          }}
          onClick={() => sendAnnotations(10)}
        >
          10
        </button>
      </div>
    </div>
  );
}
