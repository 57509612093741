import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithHistory } from "./auth/auth0-provider-with-history";
import "./tailwind.css";

ReactDOM.render(
  <React.StrictMode>
    {/* <BrowserRouter basename="/cglabel"> */}
    <BrowserRouter basename="/">
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
