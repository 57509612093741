import React from "react";
import AuthenticationButton from "../AuthenticationButton/AuthenticationButton";
import "./AuthNav.css";

const AuthNav = () => (
  <div className="login-logout-button">
    <AuthenticationButton />
  </div>
);

export default AuthNav;
