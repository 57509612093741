import { ImageCard } from "../ImageCard";

interface IImageListProps {
  imagesOnMR: any;
}

{/* <div className="flex w-screen gap-4 justify-center content-center align-center"></div> */ }

export function ImagesList({ imagesOnMR }: IImageListProps) {
  return (
    <div className="">
      <div className="grid grid-cols-3   gap-4">
        {imagesOnMR
          ? imagesOnMR.map((image: any, idx: any) => (
            <ImageCard key={idx} imagePath={image} />
          ))
          : null}
      </div>
    </div>
  );

}


// export function ImagesList({ imagesOnMR }: IImageListProps) {
//   return (
//     <div className="">
//       <div className="flex flex-wrap   gap-4">
//         {imagesOnMR
//           ? imagesOnMR.map((image: any, idx: any) => (
//             <ImageCard key={idx} imagePath={image} />
//           ))
//           : null}
//       </div>
//     </div>
//   );

// }
