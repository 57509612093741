import React, { useContext } from "react";

import MainPageContext from "../../MainPageContext";
import MainNav from "./MainNav";
import AuthNav from "./AuthNav";

import cgtraderLogo from "./CGTrader.png";

import "./NavBar.css";

export const NavBar = (props) => {
  const { userDB } = useContext(MainPageContext);

  // const { user } = props;
  return (
    <div className="navbar">
      <div className="cgtrader-logo">
        <a href="/">
          <img className="navbar-logo" src={cgtraderLogo} alt="cgtrader-logo" />
        </a>
      </div>
      <div className="pages">
        <MainNav user={userDB} />
        <AuthNav />
      </div>
    </div>
  );
};
