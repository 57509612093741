const axios = require("axios").default;

const API_URL = process.env.REACT_APP_SERVER_URL;

interface IAnnotationSingleImage {
  boundingBox: {
    x: number,
    y: number,
    width: number,
    height: number,
    key: string
  }
  mainOrAuxChoice: string;
  imageName: string;
}

interface IAnnotation {
  uuid: string;
  category: string;
  subCategory: string;
  acceptOrReject: string;
  MRAnnotations: IAnnotationSingleImage[];
}

export const getLastMainOrAusAnnotationIndex = async (
  user: any,
  job: string
) => {
  try {
    const response = await axios.get(
      `${API_URL}/modeling-request-last-index/?user_email=${user.email}&job=${job}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getUser = async (user: any) => {
  try {
    if (user) {
      const response = await fetch(`${API_URL}/get-user?user=${user.email}`);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson;
      }
      return {};
    }
  } catch (err) {
    console.log(err);
  }
};

export const getJob = async (jobName: string) => {
  try {
    const response = await axios.get(`${API_URL}/get-job`, {
      params: {
        job_name: jobName,
      },
    });
    if (response.status === 200) {
      return response.data;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const getCategoriesDescriptions = async () => {
  try {
    const response = await fetch(`${API_URL}/get-categories-descriptions`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const sendAnnotations = async (
  user: any,
  folderName: string,
  mrIndex: number,
  selectedCategory: string,
  selectedSubCategory: string,
  MRAnnotations: IAnnotation,
  complexity: number,
  acceptOrReject: string,
  job: string
) => {
  try {
    const userName = user.nickname;
    const userEmail = user.email;

    const payload = {
      _id: `${folderName}-${userName}`,
      MRAnnotations,
      user_name: userName,
      user_email: userEmail,
      modeling_request_index: mrIndex,
      selected_category: selectedCategory,
      selected_sub_category: selectedSubCategory,
      complexity: complexity,
      accept_or_reject: acceptOrReject,
      job
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/main-or-aux-annotations/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getMainOrAuxAnnotations = async (uuid: string, user: any) => {
  try {
    const userName = user.nickname;
    const response = await axios.get(
      `${API_URL}/main-or-aux-annotations/?user_name=${userName}&uuid=${uuid}`
    );
    return response.data[0];
  } catch (err) {
    console.log(err);
  }
};

export const getAnnotationsRecommendations = async (uuid: string) => {
  try {
    const response = await axios.get(
      `${API_URL}/main-or-aux-fetch-recommended-annotations/?uuid=${uuid}`
    );
    return response.data[0];
  } catch (err) {
    console.log(err);
  }
};

export const getLastAnnotationIndex = async (user: any, userDB: any) => {
  try {
    const response = await axios.get(
      `${API_URL}/modeling-request-last-index/?user_email=${user.email}&MR_initial_index=${userDB.MR_initial_index}&MR_final_index=${userDB.MR_final_index}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getDatasetImages = async (dataset: any, previous: any) => {
  try {
    const response = await fetch(
      `${API_URL}/dataset-images?dataset=${dataset}&previous=${previous}`
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const countImagesOnDataset = async (dataset: string, previous: any) => {
  try {
    const response = await fetch(
      `${API_URL}/dataset-images-count?dataset=${dataset}`
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const getDatasetJson = async (dataset: string) => {
  try {
    const response = await axios.get(`${API_URL}/get-dataset-json`, {
      params: {
        dataset,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getImagesUrlsFromMR = async (
  dataset: string,
  folder: string,
  image_name: string
) => {
  try {
    const response = await axios.get(`${API_URL}/get-image-url`, {
      params: {
        dataset,
        folder,
        image_name,
      },
    });

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
