import { useContext, useState, useEffect } from "react";
import {
  sendAdminRatingAnnotations,
  getLastAdminRatingAnnotationIndex,
} from "../../api";
import PageContext from "../StatesManager";

import "./styles.scss";

export function ComplexityButtons({ setMRIndex }) {
  const {
    user,
    complexityAnnotation,
    datasetCategory,
    folderNameState,
    imageNamesOnFolderState,
    mrIndex,
    jobName,
  } = useContext(PageContext);

  const [complexityState, setComplexityState] = useState();
  const array1to10 = [...Array(10).keys()].map((x) => ++x);

  useEffect(() => {
    const fetchData = async () => {
      if (complexityState) {
        const response = await sendAdminRatingAnnotations(
          complexityState,
          user,
          datasetCategory,
          mrIndex,
          folderNameState,
          imageNamesOnFolderState[0],
          jobName
        );

        if (response) {
          const lastIndex = await getLastAdminRatingAnnotationIndex(
            user,
            datasetCategory,
            jobName
          );
          setComplexityState(null);
          setMRIndex(lastIndex);
        }
      }
    };
    fetchData();
  }, [complexityState]);

  function sendAnnotations(complexity) {
    setComplexityState(complexity);
  }

  useEffect(() => {
    const setFromEvent = (e) => {
      if (array1to10.includes(parseInt(e.key))) {
        sendAnnotations(parseInt(e.key));
      } else if (e.key === "0") {
        sendAnnotations(10);
      }
    };
    window.addEventListener("keydown", setFromEvent);
    return () => {
      window.removeEventListener("keydown", setFromEvent);
    };
  }, [mrIndex]);

  return (
    <div className="admin-rating-buttons">
      <div className="scores-1-to-9">
        {array1to10.slice(0, -1).map((value) => {
          return (
            <button
              style={{
                backgroundColor:
                  complexityAnnotation === value ? "#00690e" : "#0563af",
              }}
              onClick={() => sendAnnotations(value)}
            >
              {value}
            </button>
          );
        })}
      </div>

      <div className="score-10">
        <button
          className={"last"}
          style={{
            backgroundColor:
              complexityAnnotation === 10 ? "#00690e" : "#0563af",
          }}
          onClick={() => sendAnnotations(10)}
        >
          10
        </button>
      </div>
    </div>
  );
}
