const axios = require("axios").default;

const API_URL = process.env.REACT_APP_SERVER_URL;

export const getJobs = async () => {
  try {
    const response = await fetch(`${API_URL}/get-jobs`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

// export const createJob = async (
//   newJobName,
//   newJobType,
//   newJobDataset,
//   newMrInitialIndex,
//   newMrFinalIndex
// ) => {
//   try {
//     const payload = {
//       job_name: newJobName,
//       job_type: newJobType,
//       job_dataset: newJobDataset,
//       initial_index: newMrInitialIndex,
//       final_index: newMrFinalIndex,
//     };
//     const config = {
//       headers: {
//         Accept: "application/json",
//       },
//     };
//     const response = await axios.post(
//       `${API_URL}/create-job/`,
//       payload,
//       config
//     );

//     return response;
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const updateJob = async (
//   id,
//   newJobName,
//   newJobType,
//   newJobDataset,
//   newMrInitialIndex,
//   newMrFinalIndex
// ) => {
//   try {
//     const payload = {
//       _id: id,
//       job_name: newJobName,
//       job_type: newJobType,
//       job_dataset: newJobDataset,
//       initial_index: newMrInitialIndex,
//       final_index: newMrFinalIndex,
//     };
//     const config = {
//       headers: {
//         Accept: "application/json",
//       },
//     };
//     const response = await axios.patch(
//       `${API_URL}/update-job/`,
//       payload,
//       config
//     );

//     return response;
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getUser = async (user) => {
  try {
    if (user) {
      const response = await fetch(`${API_URL}/get-user?user=${user.email}`);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson;
      }
      return {};
    }
  } catch (err) {
    console.log(err);
  }
};

export const getUsers = async () => {
  try {
    const response = await fetch(`${API_URL}/get-users`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const getCategoriesDescriptions = async () => {
  try {
    const response = await fetch(`${API_URL}/get-categories-descriptions`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

// export const updateCategoryDescription = async (id, category, description) => {
//   try {
//     const payload = {
//       _id: id,
//       category,
//       description,
//     };
//     const config = {
//       headers: {
//         Accept: "application/json",
//       },
//     };
//     const response = await axios.patch(
//       `${API_URL}/update-category-description/`,
//       payload,
//       config
//     );

//     return response;
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const updateSubCategoryDescription = async (
//   id,
//   subCategory,
//   description
// ) => {
//   try {
//     const payload = {
//       _id: id,
//       subCategory,
//       description,
//     };
//     const config = {
//       headers: {
//         Accept: "application/json",
//       },
//     };
//     const response = await axios.patch(
//       `${API_URL}/update-sub-category-description/`,
//       payload,
//       config
//     );

//     return response;
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getSubCategoriesDescriptions = async () => {
  try {
    const response = await fetch(`${API_URL}/get-sub-categories-descriptions`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const createUser = async (user) => {
  try {
    const payload = {
      _id: user.email,
      user_name: user.nickname,
      user_email: user.email,
      MR_initial_index: 0,
      MR_final_index: 0,
      type: user.email.split("@")[1] === "cgtrader.com" ? "admin" : "annotator",
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/create-users/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateUser = async (
  modalUserId,
  modalUserType,
  optionSelected
) => {
  try {
    const jobs = optionSelected.map((job) => job.label);
    const payload = {
      _id: modalUserId,
      jobs: jobs,
      type: modalUserType,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.patch(
      `${API_URL}/update-user/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getImages = async (idx) => {
  try {
    const response = await fetch(`${API_URL}/modeling-request?index=${idx}`);
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const getIndexOfMR = async (mrID) => {
  try {
    const response = await fetch(`${API_URL}/modeling-request?mrid=${mrID}`);
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const getDatasetImages = async (dataset, previous) => {
  try {
    const response = await fetch(
      `${API_URL}/dataset-images?dataset=${dataset}&previous=${previous}`
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const countImagesOnDataset = async (dataset, previous) => {
  try {
    const response = await fetch(
      `${API_URL}/dataset-images-count?dataset=${dataset}`
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const getComplexityGuidelinesImages = async (dataset, score) => {
  try {
    const response = await fetch(
      `${API_URL}/complexity-guideline-images?dataset=${dataset}&score=${score}`
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const getTableImages = async (idx) => {
  try {
    const response = await fetch(`${API_URL}/table-image-path?index=${idx}`);
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const getSinglePersonSittableImages = async (idx) => {
  try {
    const response = await fetch(
      `${API_URL}/single-person-sittable-image-path?index=${idx}`
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const getMultiPersonSittableImages = async (idx) => {
  try {
    const response = await fetch(
      `${API_URL}/multi-person-sittable-image-path?index=${idx}`
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const getMultiPersonSittable1KImages = async (idx) => {
  try {
    const response = await fetch(
      `${API_URL}/multi-person-sittable-image-path-1k?index=${idx}`
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
  }
};

export const getDatasetImagesCount = async (dataset) => {
  try {
    const response = await axios.get(
      `${API_URL}/dataset-images-count/?dataset=${dataset}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getImagesCount = async () => {
  try {
    const response = await axios.get(`${API_URL}/modeling-request-count/`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getTableImagesCount = async () => {
  try {
    const response = await axios.get(`${API_URL}/table-images-count/`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getSinglePersonSittableImagesCount = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/single-person-sittable-images-count/`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getMultiPersonSittableImagesCount = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/multi-person-sittable-images-count/`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getMultiPersonSittable1KImagesCount = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/multi-person-sittable-images-count-1k/`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const sendAnnotations = async (
  mainOrAuxChoices,
  user,
  modelingRequestId,
  mrIndex,
  selectedCategory,
  selectedSubCategory,
  annotationList,
  acceptOrReject
) => {
  try {
    const userName = user.nickname;
    const userEmail = user.email;

    const payload = {
      _id: `${modelingRequestId}-${userName}`,
      modeling_request_annotations: annotationList, //mainOrAuxChoices,
      user_name: userName,
      user_email: userEmail,
      modeling_request_index: mrIndex,
      selected_category: selectedCategory,
      selected_sub_category: selectedSubCategory,
      accept_or_reject: acceptOrReject,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/modeling-request-annotations/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

//export const getAnnotations = async (modelingRequestId, user) => {
export const getAnnotations = async (uuid, user) => {
  try {
    const userName = user.nickname;
    // const response = await axios.get(
    //   `${API_URL}/modeling-request-annotations/?_id=${modelingRequestId}-${userName}`
    // );
    const response = await axios.get(
      `${API_URL}/modeling-request-annotations/?user_name=${userName}&uuid=${uuid}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getLastAnnotationIndex = async (user, userDB) => {
  try {
    const response = await axios.get(
      `${API_URL}/modeling-request-last-index/?user_email=${user.email}&MR_initial_index=${userDB.MR_initial_index}&MR_final_index=${userDB.MR_final_index}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

// Complexity
export const sendComplexityAnnotations = async (
  complexity,
  user,
  imageIndex,
  category,
  imageName
) => {
  try {
    const userName = user.nickname;
    const userEmail = user.email;

    const payload = {
      _id: `${imageName}-${userName}`,
      user_name: userName,
      user_email: userEmail,
      image_index: imageIndex,
      image_name: imageName[0],
      complexity,
      category,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/complexity-annotations/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getComplexityAnnotations = async (modelingRequestId, user) => {
  try {
    const userName = user.nickname;
    const response = await axios.get(
      `${API_URL}/complexity-annotations/?_id=${modelingRequestId}-${userName}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getLastComplexityAnnotationIndex = async (
  user,
  selectedCategory
) => {
  try {
    const response = await axios.get(
      `${API_URL}/complexity-last-index/?user_email=${user.email}&category=${selectedCategory}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getJob = async (jobName) => {
  try {
    const response = await axios.get(`${API_URL}/get-job`, {
      params: {
        job_name: jobName,
      },
    });
    if (response.status === 200) {
      // const responseJson = await response.json();
      // return responseJson;
      return response.data;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const getDatasetJson = async (dataset) => {
  try {
    const response = await axios.get(`${API_URL}/get-dataset-json`, {
      params: {
        dataset,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getDatasetMarketplaceJson = async (dataset) => {
  try {
    const response = await axios.get(`${API_URL}/get-dataset-markaetplace-urls-json`, {
      params: {
        dataset,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getDataset = async (dataset) => {
  try {
    const response = await axios.get(`${API_URL}/get-dataset`, {
      params: {
        dataset,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
