import { ImageCard } from "../ImageCard";
import "./styles.scss";

interface IImageListProps {
  imagesOnMR: any;
}

export function ImagesList({ imagesOnMR }: IImageListProps) {
  return (
    <div className="">
      <div className="grid grid-cols-3   gap-4">
        {imagesOnMR
          ? imagesOnMR.map((image: any, idx: any) => (
            <ImageCard key={idx} imagePath={image} />
          ))
          : null}
      </div>
    </div>
  );

}
