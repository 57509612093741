// import "./styles.scss";

interface IImageCardProps {
  imagePath: string;
}

export function ImageCard({ imagePath }: IImageCardProps) {
  return (
    // <div className="h-screen" style={{ height: "calc(100vh - 190px)" }}>
    <div>
      <div className="h-full flex justify-center items-center">
        <img
          src={imagePath}
          alt={imagePath}
          className="h-full   object-fit"
        />
      </div>
    </div>
  );
}

// export function ImageCard({ imagePath }: IImageCardProps) {
//   return (
//     <div className="">
//       <div className="">
//         <img
//           src={imagePath}
//           alt={imagePath}
//           className="w-[22rem] m-4"
//         />
//       </div>
//     </div>
//   );
// }



// export function ImageCard({ imagePath }: IImageCardProps) {
//   return (
//     // <div className="h-screen" style={{ height: "calc(100vh - 190px)" }}>
//     <div>
//       <div className="h-full w-3/12 flex justify-center items-center">
//         <img
//           src={imagePath}
//           alt={imagePath}
//           className="h-full  max-w-max object-fit"
//         />
//       </div>
//     </div>
//   );
// }