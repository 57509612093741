import React, { useState } from 'react';

const JobsTable = ({ data, setModalJobId, setModalJobName, setModalJobType, setModalJobDataset, setModalDatasetInitialIndex, setModalDatasetFinalIndex  }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const onRowClick = async (index, jobId, jobName, jobType, jobDataset, jobInitialIndex, jobFinalIndex) => {
    setSelectedRowIndex(index);
    setModalJobId(jobId); // This will trigger a re-fetch of data
    setModalJobName(jobName);
    setModalJobType(jobType);
    setModalJobDataset(jobDataset);
    setModalDatasetInitialIndex(jobInitialIndex);
    setModalDatasetFinalIndex(jobFinalIndex);
  };

  return (
    <div className="flex justify-center ">
      <table className=" bg-white inline-block">
        <thead>
          <tr className="w-full h-16 border-gray-300 border-b py-8 bg-blue-500">
            <th className="text-center text-white font-bold  ">Job Name</th>
            <th className="text-center text-white font-bold  ">Job Type</th>
            <th className="text-center text-white font-bold  ">Job Dataset</th>
            <th className="text-center text-white font-bold  ">Initial Index</th>
            <th className="text-center text-white font-bold   px-8">Final Index</th>
          </tr>
        </thead>
        <tbody>
          {data.map((job, index) => (
            <tr
              key={index}
              className={`h-10 border-gray-300 border-b hover:bg-gray-300 ${index === selectedRowIndex && 'bg-blue-300 text-white'}`}
              onClick={() => {onRowClick(index, job._id, job.job_name, job.job_type, job.job_dataset, job.initial_index, job.final_index) }}
            >
              <td className="px-2 text-center text-sm  text-gray-800 ">{job.job_name}</td>
              <td className="px-2 text-center text-sm  text-gray-800 ">{job.job_type}</td>
              <td className="px-2 text-center text-sm  text-gray-800 ">{job.job_dataset}</td>
              <td className="px-2 text-center text-sm  text-gray-800 ">{job.initial_index}</td>
              <td className="px-2 text-center text-sm  text-gray-800 ">{job.final_index}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default JobsTable;