import { useContext, useState, useEffect } from "react";

import { getImagesUrlsFromMR, getComplexityAnnotations } from "./api";

import MainPageContext from "../../MainPageContext";
import PageContext from "./components/StatesManager";
import { ControlPanel } from "./components/ControlPanel";
import { ZoomedImage } from "./components/ZoomedImage";
import { ImagesList } from "./components/ImagesList";
import "./styles.scss";

export function Complexity() {
  const {
    user,
    userDB,
    datasetName,
    datasetPaths,
    datasetLength,
    datasetCategory,
    datasetMarketplaceURLs,
    jobName,
  } = useContext(MainPageContext);

  const [mrIndex, setMRIndex] = useState(0);
  const [folderNameState, setFolderNameState] = useState("");
  const [imageNamesOnFolderState, setImageNamesOnFolderState] = useState([]);
  const [imagesOnMR, setImagesOnMR] = useState([]);
  const [complexityAnnotation, setComplexityAnnotation] = useState();
  const [marketPlaceURLState, setMarketPlaceURLState] = useState("");

  const [isHovering, setIsHovering] = useState(false);
  const [guidelineImagesList, setGuidelineImagesList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let imageUrl;

      const imageNamesOnFolder = datasetPaths[mrIndex]?.images_in_folder;
      const folderName = datasetPaths[mrIndex]?.folder_name;

      if (datasetMarketplaceURLs){
        setMarketPlaceURLState(datasetMarketplaceURLs[folderName]);
      }

      setImageNamesOnFolderState(imageNamesOnFolder);
      setFolderNameState(folderName);

      if (imageNamesOnFolder) {
        let imagesURLs = [];
        for (const imageName of imageNamesOnFolder) {
          let imageURL = await getImagesUrlsFromMR(
            datasetName,
            folderName,
            imageName
          );
          imagesURLs.push(imageURL);
        }
        imageUrl = imagesURLs;
      }

      // TODO: add job to getComplexityAnnotation
      const annotation = await getComplexityAnnotations(folderName, user);
      if (annotation.length > 0) {
        setComplexityAnnotation(annotation[0].complexity);
      } else {
        setComplexityAnnotation(null);
      }

      setImagesOnMR(imageUrl);
    };
    fetchData();
  }, [mrIndex, datasetCategory]);

  function increment() {
    if (mrIndex + 1 < datasetLength) {
      setMRIndex(mrIndex + 1);
    }
  }

  function decrement() {
    if (mrIndex > 0) {
      setMRIndex(mrIndex - 1);
    }
  }

  const context = {
    increment,
    decrement,
    datasetPaths,
    user,
    complexityAnnotation,
    setIsHovering,
    guidelineImagesList,
    setGuidelineImagesList,
    datasetCategory,
    imagesOnMR,
    folderNameState,
    imageNamesOnFolderState,
    mrIndex,
    jobName,
  };

  return (
    <PageContext.Provider value={context}>
      <ControlPanel
        userDB={userDB}
        datasetLength={datasetLength}
        mrIndex={mrIndex}
        setMRIndex={setMRIndex}
        marketPlaceURLState={marketPlaceURLState}
      />
      {/* <div className="images">
        <ZoomedImage />
      </div> */}
      <div className="bg-adminRatingImagesBackground" >
          <ImagesList imagesOnMR={imagesOnMR} />
        </div>
      {/* <div className="complexity-text-description">
        <div className="score-description">
          3 ( typical 2-4): simple frames/structures/texture
        </div>

        <div className="score-description">
          5 ( typical 4-6): more complex frames / structure or more complex
          textiles or 3D textile structure such as over-stuffin, more different
          textiles or organic/curving structure
        </div>

        <div className="score-description">
          8 ( typical 7-9) : anything with detailed carving / moulding / unique
          patterns/textiles
        </div>
      </div> */}
    </PageContext.Provider>
  );
}
