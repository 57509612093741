import { useContext, useState, useEffect, useCallback } from "react";
import PageContext from "../StatesManager";
import { DrawAnnotations } from "../DrawAnnotations";

import "./styles.scss";

export function ImageCard({ imagePath, smallImageIndex, imageFileName }) {
  const {
    imageSelectedOnMR,
    imagesOnMR,
    getImageDims,
    imageListDims,
    imageIndexMock,
    updateCircle,
    annotationObj,
    setAnnotationObj,
    datasetName,
    folderNameState,
    imageNamesOnFolderState,
    updateAnnotation,
  } = useContext(PageContext);

  const [positionClickedImageCard, setPositionClickedImageCard] = useState({
    x: 99999,
    y: 99999,
  });

  const [
    coordinatesMultiplierWidthImageCard,
    setCoordinatesMultiplierWidthImageCard,
  ] = useState(1);
  const [
    coordinatesMultiplierHeightImageCard,
    setCoordinatesMultiplierHeightImageCard,
  ] = useState(1);

  const [mainOrAuxChoice, setMainOrAuxChoice] = useState("");

  const updateCircleOnImageCard = (annotationObjTemp) => {
    let imageName = `${datasetName}/${folderNameState}/${imageNamesOnFolderState[smallImageIndex]}`;
    if (annotationObjTemp.MRAnnotations) {
      const imageRealDim = imageListDims.filter(
        (element) => element.smallImageIndex === smallImageIndex
      )[0];

      if (imageRealDim) {
        const imageHeight = imageRealDim.imageHeight;
        const imageWidth = imageRealDim.imageWidth;

        const clientHeight = imageRealDim.clientHeight;
        const clientWidth = imageRealDim.clientWidth;

        const coordinatesMultiplierHeight = imageHeight / clientHeight;
        const coordinatesMultiplierWidth = imageWidth / clientWidth;

        setCoordinatesMultiplierHeightImageCard(
          1 / coordinatesMultiplierHeight
        );
        setCoordinatesMultiplierWidthImageCard(1 / coordinatesMultiplierWidth);

        for (
          let index = 0;
          index < annotationObjTemp.MRAnnotations.length;
          index++
        ) {
          const element = annotationObjTemp.MRAnnotations[index];
          if (element.imageName === imageName) {
            setPositionClickedImageCard(element.positionClicked);
            setMainOrAuxChoice(element.mainOrAuxChoice);
          }
        }
      }
    } else {
      setPositionClickedImageCard({
        x: 99999,
        y: 99999,
      });
      setMainOrAuxChoice("");
    }
  };

  useEffect(() => {
    if (annotationObj) {
      updateCircleOnImageCard(annotationObj);
    }
  }, [
    imageListDims,
    annotationObj.MRAnnotations,
    annotationObj,
    imageIndexMock,
    mainOrAuxChoice,
  ]);

  // const mouseClick = useCallback(
  //   (e, mainOrAux) => {
  //     const bounds = e.target.getBoundingClientRect();
  //     e.preventDefault();
  //     if (e.target.tagName === "BUTTON") {
  //       const boundingBox = null;

  //       if (imageFileName === imageNamesOnFolderState[smallImageIndex]) {
  //         let imageName = `${datasetName}/${folderNameState}/${imageNamesOnFolderState[smallImageIndex]}`;
  //         let annotationObjTemp = updateAnnotation(
  //           annotationObj,
  //           imageName,
  //           boundingBox,
  //           mainOrAux
  //         );
  //         setAnnotationObj(annotationObjTemp);
  //         updateCircle();
  //       }
  //     } else {
  //       const imageRealDim = imageListDims.filter(
  //         (element) => element.smallImageIndex === smallImageIndex
  //       )[0];

  //       const imageHeight = imageRealDim.imageHeight;
  //       const imageWidth = imageRealDim.imageWidth;

  //       const clientHeight = imageRealDim.clientHeight;
  //       const clientWidth = imageRealDim.clientWidth;

  //       const coordinatesMultiplierHeight = imageHeight / clientHeight;
  //       const coordinatesMultiplierWidth = imageWidth / clientWidth;

  //       const positionClicked = {
  //         x: (e.clientX - bounds.left) * coordinatesMultiplierWidth,
  //         y: (e.clientY - bounds.top) * coordinatesMultiplierHeight,
  //       };

  //       if (imageFileName === imageNamesOnFolderState[smallImageIndex]) {
  //         let imageName = `${datasetName}/${folderNameState}/${imageNamesOnFolderState[smallImageIndex]}`;
  //         let annotationObjTemp = updateAnnotation(
  //           annotationObj,
  //           imageName,
  //           positionClicked,
  //           mainOrAux
  //         );
  //         setAnnotationObj(annotationObjTemp);
  //       }

  //       // e.preventDefault();
  //       updateCircle();
  //     }
  //   },
  //   [imageIndexMock, imageListDims, imageSelectedOnMR]
  // );

  const mouseClick = useCallback(
    (e, mainOrAux) => {
      e.preventDefault();
      if (e.target.tagName === "BUTTON") {
        const boundingBox = null;

        if (imageFileName === imageNamesOnFolderState[smallImageIndex]) {
          let imageName = `${datasetName}/${folderNameState}/${imageNamesOnFolderState[smallImageIndex]}`;
          let annotationObjTemp = updateAnnotation(
            annotationObj,
            imageName,
            boundingBox,
            mainOrAux
          );
          setAnnotationObj(annotationObjTemp);
          updateCircle();
        }
      }
    },
    [imageIndexMock, imageListDims, imageSelectedOnMR]
  );

  // const mouseClick = (e, mainOrAux) => {
  //   const boundingBox = null;

  //   if (imageFileName === imageNamesOnFolderState[smallImageIndex]) {
  //     let imageName = `${datasetName}/${folderNameState}/${imageNamesOnFolderState[smallImageIndex]}`;
  //     let annotationObjTemp = updateAnnotation(
  //       annotationObj,
  //       imageName,
  //       boundingBox,
  //       mainOrAux
  //     );
  //     setAnnotationObj(annotationObjTemp);
  //     updateCircle();
  //   }
  // };

  return (
    <div className="image-card-div">
      <div className="canvas-bbox">
        {imageListDims.length === imagesOnMR.length ? (
          <DrawAnnotations
            imageListDims={imageListDims}
            imageIndex={smallImageIndex}
          />
        ) : (
          <></>
        )}
      </div>
      <div
        className="unselected-image-card"
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <img
          src={imagePath}
          alt="image"
          onLoad={(e) => {
            getImageDims(e, smallImageIndex);
          }}
          className="image-on-card"
          onDragStart={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="image-card-btns-div">
        <div className="top-btns">
          <div
            className={
              mainOrAuxChoice === "main"
                ? "main-selected-image-card"
                : "main-unselected-image-card"
            }
          >
            <button
              type="button"
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              onClick={(e) => {
                mouseClick(e, "main");
              }}
            >
              Main
            </button>
          </div>

          <div
            className={
              mainOrAuxChoice === "aux"
                ? "main-selected-image-card"
                : "main-unselected-image-card"
            }
          >
            <button
              type="button"
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              onClick={(e) => {
                mouseClick(e, "aux");
              }}
            >
              Aux
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
