const axios = require("axios").default;

const API_URL = process.env.REACT_APP_SERVER_URL;

export const getJobs = async (page = 1, limit = 1000) => {
  try {
    const response = await fetch(`${API_URL}/get-jobs?page=${page}&limit=${limit}`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson.jobs;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const getUsers = async (page = 1, limit = 10) => {
  try {
    const response = await fetch(`${API_URL}/get-users?page=${page}&limit=${limit}`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const updateUser = async (
  modalUserId,
  modalUserType,
  modalUserJob
) => {
  try {
    const payload = {
      _id: modalUserId,
      job: modalUserJob,
      type: modalUserType,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.patch(
      `${API_URL}/update-user/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const deleteUser = async (_id) => {
  try {
    const payload = {
      _id,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.delete(`${API_URL}/delete-user`, {
      data: payload,
      
    }, config);

    return response;
  } catch (err) {
    console.log(err);
  }
};
