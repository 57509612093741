import { useState, useContext, useEffect, useRef } from "react";
import { Stage, Layer, Rect, Transformer } from "react-konva";
import PageContext from "../StatesManager";

export function DrawAnnotations({ imageListDims, imageIndex }) {
  const [rectangles, setRectangles] = useState([]);

  const [annotations, setAnnotations] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [newAnnotation, setNewAnnotation] = useState([]);

  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (selectedId && shapeRef.current) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selectedId]);

  const {
    updateAnnotation,
    annotationObj,
    setAnnotationObj,
    datasetName,
    folderNameState,
    imageNamesOnFolderState,
    setImageSelectedOnMR,
    imageSelectedOnMR,
  } = useContext(PageContext);

  useEffect(() => {
    const setFromEvent = (e) => {
      if (
        (e.key === "c" || e.key === "Delete") &&
        imageSelectedOnMR === imageIndex
      ) {
        clearSelected(selectedId);
      }
    };

    window.addEventListener("keydown", setFromEvent);
    return () => {
      window.removeEventListener("keydown", setFromEvent);
    };
  }, [annotationObj, imageIndex, imageSelectedOnMR, trRef, selectedId]);

  const clearSelected = (selectedId) => {
    let imageName = `${datasetName}/${folderNameState}/${imageNamesOnFolderState[imageIndex]}`;
    let newRects = [];
    for (const rectangle of rectangles) {
      if (rectangle.id !== selectedId) {
        newRects.push(rectangle);
      }
    }

    setRectangles(newRects);
    selectShape(null);
    setNewAnnotation([]);
  };

  const getCoordMultiplier = () => {
    const imageRealDim = imageListDims.filter(
      (element) => element.smallImageIndex === imageIndex
    )[0];

    if (imageRealDim) {
      const imageHeight = imageRealDim.imageHeight;
      const imageWidth = imageRealDim.imageWidth;

      const clientHeight = imageRealDim.clientHeight;
      const clientWidth = imageRealDim.clientWidth;

      const coordinatesMultiplierHeight = imageHeight / clientHeight;
      const coordinatesMultiplierWidth = imageWidth / clientWidth;

      return { coordinatesMultiplierHeight, coordinatesMultiplierWidth };
    } else {
      return { coordinatesMultiplierHeight: 1, coordinatesMultiplierWidth: 1 };
    }
  };

  const updateAnnotationObj = (annotationToAdd) => {
    let { coordinatesMultiplierHeight, coordinatesMultiplierWidth } =
      getCoordMultiplier();

    const annotationToAddCopy = structuredClone(annotationToAdd);

    for (const rectangle of annotationToAddCopy) {
      let annotationToAddRealDims = rectangle;
      annotationToAddRealDims.x =
        annotationToAddRealDims.x * coordinatesMultiplierWidth;
      annotationToAddRealDims.y =
        annotationToAddRealDims.y * coordinatesMultiplierHeight;
      annotationToAddRealDims.width =
        annotationToAddRealDims.width * coordinatesMultiplierWidth;
      annotationToAddRealDims.height =
        annotationToAddRealDims.height * coordinatesMultiplierHeight;
    }

    const mainOrAux = null;
    let annotationObjTemp = updateAnnotation(
      annotationObj,
      imageName,
      annotationToAddCopy,
      mainOrAux
    );
    setAnnotationObj(annotationObjTemp);
  };

  let imageName = `${datasetName}/${folderNameState}/${imageNamesOnFolderState[imageIndex]}`;

  useEffect(() => {
    if (annotationObj.MRAnnotations && imageListDims.length > 0) {
      let prevAnnotations = annotationObj.MRAnnotations.filter(
        (element) => element.imageName === imageName
      );

      let { coordinatesMultiplierHeight, coordinatesMultiplierWidth } =
        getCoordMultiplier();

      const prevAnnotationsCopy = structuredClone(prevAnnotations);

      if (prevAnnotationsCopy.length > 0) {
        setNewAnnotation([]);

        for (const rectangle of prevAnnotationsCopy[0]?.boundingBox) {
          let scaledBBox = rectangle;
          scaledBBox.x = scaledBBox.x / coordinatesMultiplierWidth;
          scaledBBox.y = scaledBBox.y / coordinatesMultiplierHeight;
          scaledBBox.width = scaledBBox.width / coordinatesMultiplierWidth;
          scaledBBox.height = scaledBBox.height / coordinatesMultiplierHeight;
        }

        if (prevAnnotationsCopy[0]?.boundingBox.length > 0) {
          if (
            coordinatesMultiplierHeight !== 1 &&
            coordinatesMultiplierWidth !== 1
          ) {
            setRectangles(prevAnnotationsCopy[0]?.boundingBox);
          }
        }
      }
    } else if (!annotationObj.MRAnnotations && imageListDims.length > 0) {
      setRectangles([]);
    }
  }, [imageListDims, annotationObj]);

  useEffect(() => {
    updateAnnotationObj(rectangles);
  }, [rectangles]);

  const handleMouseDown = (event) => {
    setImageSelectedOnMR(imageIndex);
    if (newAnnotation.length === 0) {
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewAnnotation([{ x, y, width: 0, height: 0, key: "0" }]);
    }
  };

  const handleMouseUp = (event) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();

      if (Math.abs(x - sx) > 10 && Math.abs(y - sy) > 10) {
        const annotationToAdd = {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: annotations.length + 1,
        };
        annotations.push(annotationToAdd);
        setNewAnnotation([]);
        setAnnotations(annotations);

        const rectangleToAdd = {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          id: rectangles.length + 1,
        };

        rectangles.push(rectangleToAdd);
        setRectangles(rectangles);

        updateAnnotationObj(rectangles);
        selectShape(annotations.length + 2);
      } else {
        setNewAnnotation([]);
      }
    }
  };

  const handleMouseMove = (event) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewAnnotation([
        {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: "0",
        },
      ]);
    }
  };

  const rectanglesToDraw = [...rectangles, ...newAnnotation];

  const canvasDimension = imageListDims.filter(
    (element) => element.smallImageIndex === imageIndex
  );

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
      handleMouseDown(e);
    }
  };

  const Rectangle = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = useRef();
    const trRef = useRef();

    useEffect(() => {
      if (isSelected) {
        // we need to attach transformer manually
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);

    return (
      <>
        <Rect
          fill="rgba(255,0,0,0.2)"
          stroke="red"
          strokeWidth={4}
          strokeScaleEnabled={false}
          opacity={1.0}
          onClick={onSelect}
          onTap={onSelect}
          ref={shapeRef}
          {...shapeProps}
          draggable
          onDragEnd={(e) => {
            onChange({
              ...shapeProps,
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
          onTransformEnd={(e) => {
            // transformer is changing scale of the node
            // and NOT its width or height
            // but in the store we have only width and height
            // to match the data better we will reset scale on transform end
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            });
          }}
        />
        {isSelected && (
          <Transformer
            ref={trRef}
            rotateEnabled={false}
            keepRatio={false}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </>
    );
  };

  return (
    <Stage
      onMouseDown={checkDeselect}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onTouchStart={checkDeselect}
      width={canvasDimension[0]?.clientWidth}
      height={canvasDimension[0]?.clientHeight}
    >
      <Layer>
        {rectanglesToDraw.map((rect, i) => {
          return (
            <Rectangle
              key={i}
              shapeProps={rect}
              isSelected={rect.id === selectedId}
              onSelect={() => {
                setImageSelectedOnMR(imageIndex);
                selectShape(rect.id);
              }}
              onChange={(newAttrs) => {
                const rects = rectangles.slice();
                rects[i] = newAttrs;
                setRectangles(rects);
              }}
            />
          );
        })}
      </Layer>
    </Stage>
  );
}
