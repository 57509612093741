import { useEffect, useState } from "react";

import axios from 'axios';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

import { getJobs, getDatasets, getUsers, createJob, updateJob, deleteJob, userJobStatus, getComplexityAnnotationsUsers, getAdminRatingAnnotationsUsers } from "./api";
import { NavBar } from "../../components/NavBar/NavBar";
import JobsTable from "./components/JobsTable";
import Modal from 'react-modal';

const API_URL = process.env.REACT_APP_SERVER_URL;


export function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [users, setUsers] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [saved, setSaved] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const jobsPerPage = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isJobViewModalOpen, setIsJobViewModalOpen] = useState(false);

  const [modalJobId, setModalJobId] = useState("");
  const [modalJobName, setModalJobName] = useState("");
  const [modalJobType, setModalJobType] = useState("");
  const [modalJobDataset, setModalJobDataset] = useState("");
  const [modalDatasetInitialIndex, setModalDatasetInitialIndex] = useState("");
  const [modalDatasetFinalIndex, setModalDatasetFinalIndex] = useState("");
  const [modalAnnotations, setModalAnnotations] = useState([]);


  const fetchData = async () => {
    const jobsDB = await getJobs(currentPage, jobsPerPage);
    const datasetsResponse = await getDatasets();
    const usersResponse = await getUsers();

    if (jobsDB) {
      setJobs(jobsDB.jobs);
      setTotalPages(jobsDB.totalPages);
    }

    if (usersResponse) {
      setUsers(usersResponse);
    }

    if (datasetsResponse) {
      setDatasets(datasetsResponse.datasets);
    }
  };


  const fetchJobData = async () => {

    let annotationsResponse;
    if (modalJobType === "admin-rating") {
      annotationsResponse = await getAdminRatingAnnotationsUsers(modalJobName)
    } else if (modalJobType === "complexity") {
      annotationsResponse = await getComplexityAnnotationsUsers(modalJobName)
    }

    if (annotationsResponse) {
      setModalAnnotations(annotationsResponse)
    }

  };

  useEffect(() => {
    fetchData();
  }, [saved, currentPage]);


  useEffect(() => {
    fetchJobData();
  }, [isJobViewModalOpen, modalJobType, modalJobId]);


  const data = jobs.map((job) => {
    return {
      _id: job?._id,
      job_name: job?.job_name,
      job_type: job?.job_type,
      job_dataset: job?.job_dataset,
      initial_index: job?.initial_index,
      final_index: job?.final_index,
    };
  });

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onJobViewModalOpen = () => {
    setIsJobViewModalOpen(true);
  };

  const onJobViewModalClose = () => {
    setIsJobViewModalOpen(false);
  };




  const onSaveClick = async () => {
    try {
      const response = await createJob(
        modalJobName,
        modalJobType,
        modalJobDataset,
        modalDatasetInitialIndex,
        modalDatasetFinalIndex
      );

      if (response?.data) {
        setSaved(true); // This will trigger a re-fetch of data
        setIsModalOpen(false);
        setModalJobId("");
        setModalJobName("");
        setModalJobType("");
        setModalJobDataset("");
        setModalDatasetInitialIndex("");
        setModalDatasetFinalIndex("");
      } else {
        console.log('Error creating job.');
      }
    } catch (error) {
      console.error('Error while saving job: ', error);
    }
  };


  return (
    <>
      <NavBar />
      <div className="mt-20 flex flex-col justify-center">
        <div className="flex justify-center">
          <button
            className="mx-2 my-5 px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={onModalOpen}
          >
            Create Job
          </button>
          <button
            className="mx-2 my-5 px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={onJobViewModalOpen}
          >
            View Job
          </button>

        </div>

        <JobsTable
          data={data}
          setModalJobName={setModalJobName}
          setModalJobType={setModalJobType}
          setModalJobDataset={setModalJobDataset}
          setModalDatasetInitialIndex={setModalDatasetInitialIndex}
          setModalDatasetFinalIndex={setModalDatasetFinalIndex}
          setModalJobId={setModalJobId}
        />
        <div className="my-5 flex justify-center">
          <button
            className={`px-4 py-2 bg-blue-500 text-white rounded-md ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
          >
            Previous
          </button>
          <span className="mx-4 text-2xl mb-4 font-bold text-white">Page {currentPage} of {totalPages}</span>
          <button
            className={`px-4 py-2 bg-blue-500 text-white rounded-md ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`}
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((page) => Math.min(page + 1, totalPages))}
          >
            Next
          </button>
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={onModalClose}
          contentLabel="Create Job"
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 p-5 bg-white rounded shadow-lg outline-none"
          overlayClassName="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50"
        >
          <h2 className="text-2xl mb-4 text-center">Create Job</h2>
          <div className="mb-4">
            <label className="py-2 block text-gray-700 text-sm font-bold mb-2">
              Job Name
            </label>
            <input
              value={modalJobName}
              onChange={(event) => setModalJobName(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <label className="py-2 block text-gray-700 text-sm font-bold mb-2">
              Job Type
            </label>
            <select
              value={modalJobType}
              onChange={(event) => setModalJobType(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Select a job type...</option>
              <option value="admin-rating">Admin Rating</option>
              <option value="complexity">Complexity</option>
            </select>
            <label className="py-2 block text-gray-700 text-sm font-bold mb-2">
              Job Dataset
            </label>
            <select
              value={modalJobDataset}
              onChange={(event) => setModalJobDataset(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Select a dataset...</option>
              {datasets.map((dataset) =>
                <option key={dataset._id} value={dataset.dataset_name}>{dataset.dataset_name}</option>
              )}
            </select>
            <div className="flex flex-row py-4 justify-center items-center">
              <div className="flex flex-col justify-center items-center">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Initial Index
                </label>
                <input
                  type="number"
                  min="0"
                  step="1"
                  value={modalDatasetInitialIndex}
                  onChange={(event) => {
                    if (event.target.value >= 0) {
                      setModalDatasetInitialIndex(parseInt(event.target.value))
                    }
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex flex-col justify-center items-center">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Final Index
                </label>
                <input
                  type="number"
                  min="0"
                  step="1"
                  value={modalDatasetFinalIndex}
                  onChange={(event) => {
                    if (event.target.value >= 0) {
                      setModalDatasetFinalIndex(parseInt(event.target.value))
                    }
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={onSaveClick}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save
            </button>
            <button
              onClick={onModalClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          </div>
        </Modal>


        <Modal
          isOpen={isJobViewModalOpen}
          onRequestClose={onJobViewModalClose}
          contentLabel="View Job"
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 p-5 bg-white rounded shadow-lg outline-none"
          overlayClassName="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50"
        >
          <div className="max-h-96 overflow-auto">
            <h2 className="text-2xl mb-4 text-center">View Job</h2>
            <div className="mb-4">
              <label className="py-2 block text-gray-700 text-sm font-bold mb-2">
                Job Name
              </label>
              <input
                value={modalJobName}
                className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <label className="py-2 block text-gray-700 text-sm font-bold mb-2">
                Job Type
              </label>
              <input
                value={modalJobType}
                className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <label className="py-2 block text-gray-700 text-sm font-bold mb-2">
                Job Dataset
              </label>
              <input
                value={modalJobDataset}
                className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <div className="flex flex-row py-4 justify-center items-center">
                <div className="flex flex-col justify-center items-center">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Initial Index
                  </label>
                  <input
                    value={modalDatasetInitialIndex}
                    className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="flex flex-col justify-center items-center">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Final Index
                  </label>
                  <input
                    value={modalDatasetFinalIndex}
                    className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
            </div>

            <div className="px-2 py-6">
              <h3 className="text-xl mt-6">Annotations:</h3>
              {modalAnnotations.length > 0 ? (
                <ul className="list-disc ml-5">
                  {modalAnnotations.map((annotation, i) => (
                    <li key={i}>
                      User {annotation.userId} created {annotation.annotationsCount} annotations
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No annotations for this job</p>
              )}
            </div>

            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                onClick={async () => {
                try {
                  let response;
                  let data;
                  if (modalJobType === "admin-rating") {
                    response = await axios.get(`${API_URL}/admin-rating-annotations-all-from-job?job=${modalJobName}`);
                    data = response.data.filter(item => item.rating !== 9999)
                    .map(item => ({ uuid: item.uuid, rating: item.rating, user_email: item.user_email }));
                  } else if (modalJobType === "complexity") {
                    response = await axios.get(`${API_URL}/complexity-annotations-all-from-job?job=${modalJobName}`);
                    data = response.data.filter(item => item.complexity !== 9999)
                    .map(item => ({ uuid: item.uuid, complexity: item.complexity, user_email: item.user_email }));
                  }

                  const csv = Papa.unparse(data);
                  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
                  saveAs(blob, `annotations_${modalJobName}.csv`);
                } catch (error) {
                  console.error(error);
                }
              }}
              >
                Save CSV
              </button>

              <button
                onClick={onJobViewModalClose}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>

          </div>
        </Modal>

      </div>
    </>
  );
}
