import "./styles.css";

export function Categories({
  categories,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
}: any) {
  const availableSubCategory = categories.find(
    (c: any) => c.category === selectedCategory
  );

  return (
    <div className="categories-container">
      <div>
        <select
          placeholder="Category"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option>--Choose Category--</option>
          {categories.map((value: any, key: any) => {
            return (
              <option value={value.category} key={key}>
                {value.category}
              </option>
            );
          })}
        </select>
      </div>

      <div>
        <select
          placeholder="Sub-Category"
          value={selectedSubCategory}
          onChange={(e) => setSelectedSubCategory(e.target.value)}
        >
          <option>--Choose Sub-Category--</option>
          {availableSubCategory?.subcategories.map((value: any, key: any) => {
            return (
              <option value={value} key={key}>
                {value}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
