import { useState, useEffect, useContext, useRef } from "react";
import { ControlPanel } from "./components/ControlPanel";
import { ImagesGallery } from "./components/ImagesGallery";
import {
  getImagesUrlsFromMR,
  getMainOrAuxAnnotations,
  getAnnotationsRecommendations,
} from "./api";
import { Loading } from "../../components/Loading/Loading";
import PageContext from "./components/StatesManager";
import MainPageContext from "../../MainPageContext";

import "./styles.scss";

interface IDatasetPaths {
  folder_name: string;
  images_in_folder: string[];
}

interface IDatasetPaths extends Array<IDatasetPaths> { }

interface ILoading {
  imagesLoaded: boolean;
}

interface IImageListDims {
  smallImageIndex: number;
  clientHeight: number;
  clientWidth: number;
  imageHeight: number;
  imageWidth: number;
}



interface IBBox {
  x: number;
  y: number;
  width: number;
  height: number;
  key: string;
}

interface IAnnotationSingleImage {
  boundingBox: IBBox[];
  mainOrAuxChoice: string;
  imageName: string;
}

interface IAnnotation {
  uuid: string;
  category: string;
  subCategory: string;
  acceptOrReject: string;
  MRAnnotations: IAnnotationSingleImage[];
}

export function MainOrAux() {
  const [mrIndex, setMRIndex] = useState(0);
  const [folderNameState, setFolderNameState] = useState("");
  const [imageNamesOnFolderState, setImageNamesOnFolderState] = useState<
    string[]
  >([]);
  const [imagesOnMR, setImagesOnMR] = useState<[] | string[]>([]);
  const [imageListDims, setImageListDims] = useState<Array<IImageListDims>>([]);
  const [imageSelectedOnMR, setImageSelectedOnMR] = useState(0);
  const [show, setShow] = useState<boolean>(false);
  const counter = useRef(0);
  const [imageIndexMock, setImageIndexMock] = useState(0);
  const [annotationObj, setAnnotationObj] = useState<IAnnotation | {}>({});

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedComplexity, setSelectedComplexity] = useState(9999);
  const [acceptOrRejectMarker, setAcceptOrRejectMarker] = useState(false);


  const {
    user,
    userDB,
    datasetName,
    datasetPaths,
    datasetInitialIndex,
    datasetFinalIndex,
    datasetLength,
    jobName
  } = useContext(MainPageContext);


  useEffect(() => {
    const getImagesOnMR = async () => {
      const imageNamesOnFolder = datasetPaths[mrIndex]?.images_in_folder;
      const folderName = datasetPaths[mrIndex]?.folder_name;

      setImageNamesOnFolderState(imageNamesOnFolder);
      setFolderNameState(folderName);
      if (imageNamesOnFolder) {
        let imagesURLs: Array<string> = [];
        for (const imageName of imageNamesOnFolder) {
          let imageURL = await getImagesUrlsFromMR(
            datasetName,
            folderName,
            imageName
          );
          imagesURLs.push(imageURL);
        }

        const annotation = await getMainOrAuxAnnotations(folderName, user);

        const annotationsRecommendations = await getAnnotationsRecommendations(folderName);
        

        if (annotation) {
          setAnnotationObj(annotation);
          setSelectedCategory(annotation.selected_category);
          setSelectedSubCategory(annotation.selected_sub_category);
          setAcceptOrRejectMarker(annotation.accept_or_reject);
          setSelectedComplexity(annotation.complexity);
        } else if (annotationsRecommendations){
          // annotationsRecommendations.main_image
          setAnnotationObj(annotationsRecommendations);
          setSelectedCategory(annotationsRecommendations.category);
          setSelectedSubCategory(annotationsRecommendations.sub_category);
          setSelectedComplexity(annotationsRecommendations.complexity);
        }

        setImagesOnMR(imagesURLs);
      }
    };
    getImagesOnMR();
  }, [datasetPaths, mrIndex]);

  function resetInitialState() {
    counter.current = 0;
    setShow(false);
    setImageListDims([]);
    setImagesOnMR([]);
    setImageSelectedOnMR(0);
    setAnnotationObj({});
    setSelectedCategory("");
    setSelectedSubCategory("");
    setSelectedComplexity(9999);
    setAcceptOrRejectMarker(false);
  }

  function increment() {
    if (mrIndex + 1 < datasetLength) {
      resetInitialState();
      setMRIndex(mrIndex + 1);
    }
  }

  function decrement() {
    if (mrIndex > 0) {
      resetInitialState();
      setMRIndex(mrIndex - 1);
    }
  }


  function ShowLoading({ imagesLoaded }: ILoading) {
    if (!imagesLoaded) {
      return (
        <div className="loading-animation">
          <Loading />
        </div>
      );
    }
    return <></>;
  }

  function getImageDims(e: any, smallImageIndex: number) {
    const imageHeight: number = e.target.naturalHeight;
    const imageWidth: number = e.target.naturalWidth;

    const clientHeight: number = e.target.clientHeight;
    const clientWidth: number = e.target.clientWidth;

    if (clientHeight > 0) {
      const obj: IImageListDims = {
        smallImageIndex,
        clientHeight,
        clientWidth,
        imageHeight,
        imageWidth,
      };
      if (imageListDims.length === 0) {
        setImageListDims([obj]);
      } else {
        setImageListDims([...imageListDims, obj]);
      }

      counter.current += 1;
      if (counter.current >= imagesOnMR.length) {
        setShow(true);
      }
    }
  }

  function updateCircle() {
    setImageIndexMock(imageIndexMock + 1);
  }


  const updateAnnotation = (
    annotationObjTemp: IAnnotation,
    imageName: string,
    boundingBox: IBBox[],
    mainOrAux: string
  ) => {
    if (!annotationObjTemp.MRAnnotations && boundingBox) {
      annotationObjTemp.MRAnnotations = [
        {
          imageName,
          boundingBox,
          mainOrAuxChoice: mainOrAux,
        },
      ];
    } else if (annotationObjTemp.MRAnnotations) {
      const imageNamesOnAnnotation = annotationObjTemp.MRAnnotations.map(
        (annotation: any) => {
          return annotation.imageName;
        }
      );

      if (imageNamesOnAnnotation.includes(imageName) && boundingBox) {
        const NewMRAnnotations = annotationObjTemp.MRAnnotations.map(
          (annotation: any) => {
            if (annotation.imageName !== imageName) {
              return annotation;
            } else {
              annotation.boundingBox = boundingBox;
              if (mainOrAux) {
                annotation.mainOrAuxChoice = mainOrAux;
              }
              return annotation;
            }
          }
        );

        annotationObjTemp.MRAnnotations = NewMRAnnotations;
      } else if (
        // when annotation is being updated through the buttons on image card
        imageNamesOnAnnotation.includes(imageName) &&
        !boundingBox
      ) {
        const NewMRAnnotations = annotationObjTemp.MRAnnotations.map(
          (annotation: any) => {
            if (annotation.imageName !== imageName) {
              return annotation;
            } else {
              // if clicking on an already selected button, unselect it
              if (annotation.mainOrAuxChoice === mainOrAux) {
                annotation.mainOrAuxChoice = "";
              } else {
                annotation.mainOrAuxChoice = mainOrAux;
              }
              return annotation;
            }
          }
        );
        annotationObjTemp.MRAnnotations = NewMRAnnotations;
      } else if (!imageNamesOnAnnotation.includes(imageName) && boundingBox) {
        annotationObjTemp.MRAnnotations.push({
          imageName,
          boundingBox,
          mainOrAuxChoice: mainOrAux,
        });
      }
    }

    return annotationObjTemp;
  };


  function clearAllAnnotations() {
    setAnnotationObj({});
    setSelectedCategory("");
    setSelectedSubCategory("");
    setAcceptOrRejectMarker(false);
    updateCircle();
  }

  const context = {
    imageSelectedOnMR,
    imagesOnMR,
    setImageSelectedOnMR,
    getImageDims,
    updateCircle,
    imageIndexMock,
    imageListDims,
    annotationObj,
    setAnnotationObj,
    datasetName,
    folderNameState,
    imageNamesOnFolderState,
    updateAnnotation,
    selectedComplexity,
    setSelectedComplexity
  };

  return (
    <div className="page">
      <PageContext.Provider value={context}>
        <div className="main-or-aux-page">
          <div className="control-panel-div">
            <ControlPanel
              increment={increment}
              decrement={decrement}
              mrIndex={mrIndex}
              setMRIndex={setMRIndex}
              resetInitialState={resetInitialState}
              datasetLength={datasetLength}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
              selectedComplexity={selectedComplexity}
              annotationObj={annotationObj}
              folderNameState={folderNameState}
              setFolderNameState={setFolderNameState}
              user={user}
              userDB={userDB}
              acceptOrRejectMarker={acceptOrRejectMarker}
              show={show}
              clearAllAnnotations={clearAllAnnotations}
              jobName={jobName}
            />
          </div>

          <ShowLoading imagesLoaded={show} />
          <div className="images-div">
            <div className="images-gallery-div">
              <ImagesGallery
                images={imagesOnMR}
                imageNames={imageNamesOnFolderState}
              />
            </div>
          </div>
        </div>
      </PageContext.Provider>
    </div>
  );
}
