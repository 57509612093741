import { useAuth0 } from "@auth0/auth0-react";

import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Loading } from "./components/Loading/Loading";
import { ProtectedRoute } from "./auth/protected-route";
import { ApplicationsPage } from "./pages/applications";
import { MainOrAux } from "./pages/mainOrAux";
import { Complexity } from "./pages/complexity";
import { AdminRating } from "./pages/adminRating";
import { Users } from "./pages/users";
import { Jobs } from "./pages/jobs";
import { Datasets } from "./pages/datasets";
import { getUser, createUser, getJob, getDatasetJson, getDataset, getDatasetMarketplaceJson } from "./api";

import MainPageContext from "./MainPageContext";

const App = () => {
  const [userDB, setUserDB] = useState({});
  const { user, isLoading } = useAuth0();
  const [datasetName, setDatasetName] = useState("");
  const [datasetInitialIndex, setDatasetInitialIndex] = useState(0);
  const [datasetFinalIndex, setDatasetFinalIndex] = useState(0);
  const [datasetPaths, setDatasetPaths] = useState([]);
  const [datasetMarketplaceURLs, setDatasetMarketplaceURLs] = useState();
  const [datasetLength, setDatasetLength] = useState(0);
  const [datasetCategory, setDatasetCategory] = useState("");
  const [jobType, setJobType] = useState();
  const [jobName, setJobName] = useState();

  const fetchData = async (user) => {
    const userDB_ = await getUser(user);
    if (userDB_) {
      setUserDB(userDB_);
    }
  };

  useEffect(() => {
    if (user) {
      fetchData(user);
    }
  }, [user]);

  useEffect(() => {
    if (!userDB?.user_email && userDB !== undefined) {
      createUser(user);
    }
  }, [userDB]);

  useEffect(() => {
    console.log(userDB);
  }, [userDB]);

  useEffect(() => {
    const getUserJobs = async () => {
      const response = await getUser(user);
      const jobNameResponse = response.job;

      if (jobNameResponse) {
        const jobInfo = await getJob(jobNameResponse);
        const job_type = await jobInfo.job_type;
        const dataset = await getDataset(jobInfo.job_dataset);
        if (dataset?.category) {
          const category = dataset.category;
          setDatasetCategory(category);
        }

        setJobName(jobNameResponse);
        setJobType(job_type);
        setDatasetName(jobInfo.job_dataset);
        setDatasetInitialIndex(jobInfo.initial_index);
        setDatasetFinalIndex(jobInfo.final_index);
      }
    };

    if (user) {
      getUserJobs();
    }
  }, [user]);

  useEffect(() => {
    const getDatasetPaths = async () => {
      if (datasetInitialIndex !== 0 && datasetFinalIndex !== 0) {
        const response = await getDatasetJson(datasetName);
        const datasetSliced = response.slice(
          datasetInitialIndex - 1,
          datasetFinalIndex
        );
        setDatasetPaths(datasetSliced);

        try {
          const response = await getDatasetMarketplaceJson(datasetName);
          // const datasetMarketplaceURLsSliced = response.slice(
          //   datasetInitialIndex - 1,
          //   datasetFinalIndex
          // );
          setDatasetMarketplaceURLs(response);
        } catch {
          console.log("error fetching marketplace urls")
        }
        setDatasetLength(datasetSliced.length);
      }
    };
    getDatasetPaths();
  }, [datasetName, datasetInitialIndex, datasetFinalIndex]);

  if (isLoading && !userDB) {
    return <Loading />;
  }

  function Login() {
    return <h3>Login</h3>;
  }

  function ConditionalRoutes(userType) {
    if (userType === "admin") {
      return (
        <>
          <ProtectedRoute
            exact
            path="/cglabel/main-or-aux"
            component={MainOrAux}
          />
          <ProtectedRoute
            exact
            path="/cglabel/complexity"
            component={Complexity}
          />
          <ProtectedRoute
            exact
            path="/cglabel/admin-rating"
            component={Complexity}
          />
          <ProtectedRoute exact path="/users" component={Users} />
          <ProtectedRoute exact path="/jobs" component={Jobs} />
          <ProtectedRoute exact path="/datasets" component={Datasets} />
        </>
      );
    } else {
      return <></>;
    }
  }

  const context = {
    user,
    userDB,
    datasetName,
    datasetInitialIndex,
    datasetFinalIndex,
    datasetPaths,
    datasetMarketplaceURLs,
    datasetLength,
    datasetCategory,
    jobType,
    jobName,
  };

  if (userDB) {
    return (
      <MainPageContext.Provider value={context}>
        <Switch>
          <ProtectedRoute exact path="/" component={ApplicationsPage} />
          <ProtectedRoute exact path="/cglabel" component={ApplicationsPage} />
          <Route exact path="/login" component={Login} />

          {userDB.type === "admin" ||
          userDB.type === "cgtrader-user" ||
          userDB.type === "annotator" ? (
            <>
              {userDB.type === "admin" || userDB.type === "annotator" ? (
                <>
                  <ProtectedRoute
                    exact
                    path="/cglabel/main-or-aux"
                    component={MainOrAux}
                  />
                  <ProtectedRoute
                    exact
                    path="/cglabel/complexity"
                    component={Complexity}
                  />
                  <ProtectedRoute
                    exact
                    path="/cglabel/admin-rating"
                    component={AdminRating}
                  />
                </>
              ) : (
                <></>
              )}

              <ProtectedRoute exact path="/users" component={Users} />
              <ProtectedRoute exact path="/jobs" component={Jobs} />
              <ProtectedRoute exact path="/datasets" component={Datasets} />
            </>
          ) : (
            <></>
          )}
        </Switch>
      </MainPageContext.Provider>
    );
  }
};

export default App;
