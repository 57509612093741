import { useContext } from "react";
import { Link } from "react-router-dom";
import { NavBar } from "../../components/NavBar/NavBar";
import MainPageContext from "../../MainPageContext";


import "./styles.scss";

export const ApplicationsPage = () => {
  const { jobType } = useContext(MainPageContext);

  return (
    <div className="applications">
      <NavBar />

      {jobType === "main-or-aux" ? (
        <Link to="/cglabel/main-or-aux" className="main-or-aux">
          <button className="applications-button main-or-aux-button">
            Main Or Aux
          </button>
        </Link>
      ) : null}

      {jobType === "complexity" ? (
        <Link to="/cglabel/complexity" className="complexity">
          <button className="applications-button complexity-button">
            Complexity
          </button>
        </Link>
      ) : null}

      {jobType === "admin-rating" ? (
        <Link to="/cglabel/admin-rating" className="admin-rating">
          <button className="applications-button complexity-button">
            Admin Rating
          </button>
        </Link>
      ) : null}
    </div>
  );
};
