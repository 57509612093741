const axios = require("axios").default;

const API_URL = process.env.REACT_APP_SERVER_URL;

// export const getDatasets = async () => {
//   try {
//     const response = await fetch(`${API_URL}/get-datasets`);
//     if (response.ok) {
//       const responseJson = await response.json();
//       return responseJson;
//     }
//     return {};
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getDatasets = async (page = 1, limit = 10) => {
  try {
    const response = await fetch(`${API_URL}/get-datasets?page=${page}&limit=${limit}`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const createDataset = async (
  newDatasetName,
  newDatasetDescription,
) => {
  try {
    const payload = {
      dataset_name: newDatasetName,
      dataset_description: newDatasetDescription,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/create-dataset/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateDataset = async (
  id,
  newDatasetDescription
) => {
  try {
    const payload = {
      _id: id,
      dataset_description: newDatasetDescription,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.patch(
      `${API_URL}/update-dataset/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};


