import { useEffect, useState, useContext } from "react";
import { getJobs, getUsers, updateUser, deleteUser } from "./api";
import MainPageContext from "../../MainPageContext";
import { NavBar } from "../../components/NavBar/NavBar";
import UsersTable from "./components/UsersTable";
import Modal from 'react-modal';



export function Users() {
  const { user } = useContext(MainPageContext);
  const [users, setUsers] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const usersPerPage = 20;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const [modalUserName, setModalUserName] = useState("");
  const [modalUserEmail, setModalUserEmail] = useState("");
  const [modalUserJob, setModalUserJob] = useState("");
  const [modalUserType, setModalUserType] = useState("");
  const [modalUserId, setModalUserId] = useState("");
  
  
  const fetchData = async () => {
    const usersDB = await getUsers(currentPage, usersPerPage);
    const jobsDB = await getJobs();
    if (usersDB) {
      setUsers(usersDB.users);
      setTotalPages(usersDB.totalPages);
    }
    if (jobsDB) {
      setJobs(jobsDB);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user, currentPage, saved, deleted]);

  const data = users.map((user) => {
    return {
      _id: user?._id,
      user_email: user?.user_email,
      user_name: user?.user_name,
      job: user?.job,
      type: user?.type,
    };
  });

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };


  const onSaveClick = async () => {
    try {
      const response = await updateUser(
        modalUserId,
        modalUserType,
        modalUserJob
      );
      
      if (response?.data) {
        setSaved(true); // This will trigger a re-fetch of data
        setIsModalOpen(false);
        setModalUserName("");
        setModalUserJob("");
        setModalUserType("");
      } else {
        console.log('Error creating user.');
      }
    } catch (error) {
      console.error('Error while saving user: ', error);
    }
  };

  const onDeleteClick = async () => {
    try {
      const response = await deleteUser(
        modalUserId
      );
      
      if (response) {
        setDeleted(true); // This will trigger a re-fetch of data
        setIsDeleteModalOpen(false);
        setModalUserName("");
        setModalUserEmail("");
        setModalUserJob("");
        setModalUserType("");
      } else {
        console.log('Error creating user.');
      }
    } catch (error) {
      console.error('Error while saving user: ', error);
    }
  };



  return (
    <>
      <NavBar />
      <div className="mt-20 flex flex-col justify-center">
        <div className="flex justify-center">
          <button
            className="mx-2 my-5 px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={onModalOpen}
          >
            Update User
          </button>
          <button
            className="mx-2 my-5 px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={onDeleteModalOpen}
          >
            Delete User
          </button>

        </div>

        <UsersTable
          data={data}
          setModalUserType={setModalUserType}
          setModalUserJob={setModalUserJob}
          setModalUserId={setModalUserId}
          setModalUserEmail={setModalUserEmail}
        />



        <div className="my-5 flex justify-center">
          <button
            className={`px-4 py-2 bg-blue-500 text-white rounded-md ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
          >
            Previous
          </button>
          <span className="mx-4 text-2xl mb-4 font-bold text-white">Page {currentPage} of {totalPages}</span>
          <button
            className={`px-4 py-2 bg-blue-500 text-white rounded-md ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`}
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((page) => Math.min(page + 1, totalPages))}
          >
            Next
          </button>
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={onModalClose}
          contentLabel="Create Job"
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 p-5 bg-white rounded shadow-lg outline-none"
          overlayClassName="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50"
        >
          <h2 className="text-2xl mb-4 text-center">Update User</h2>
          <div className="mb-4">
            <label className="py-2 block text-gray-700 text-sm font-bold mb-2">
              User Type
            </label>
            <select
              value={modalUserType}
              onChange={(event) => setModalUserType(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Select a user type...</option>
              <option value="admin">Admin</option>
              <option value="annotator">Annotator</option>
            </select>
            <label className="py-2 block text-gray-700 text-sm font-bold mb-2">
              Job
            </label>
            <select
              value={modalUserJob}
              onChange={(event) => setModalUserJob(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Select a job...</option>
              {jobs.map((job) => 
                <option key={job._id} value={job.job_name}>{job.job_name}</option>
              )}
            </select>

          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={onSaveClick}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save
            </button>
            <button
              onClick={onModalClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          </div>
        </Modal>


        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={onDeleteModalClose}
          contentLabel="Create Job"
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 p-5 bg-white rounded shadow-lg outline-none"
          overlayClassName="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50"
        >
          <h2 className="text-2xl mb-4 text-center">Delete user {modalUserEmail}?</h2>
          <div className="flex items-center justify-center">
            <button
              onClick={onDeleteClick}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mx-4 rounded focus:outline-none focus:shadow-outline"
            >
              Delete
            </button>
            <button
              onClick={onDeleteModalClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 mx-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          </div>
        </Modal>

      </div>
    </>
  );
}
