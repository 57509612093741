import { useContext, useState, useEffect } from "react";

import { getImagesUrlsFromMR, getAdminRatingAnnotations } from "./api";

import MainPageContext from "../../MainPageContext";
import PageContext from "./components/StatesManager";
import { ControlPanel } from "./components/ControlPanel";
import { ImagesList } from "./components/ImagesList";
import "./styles.scss";

export function AdminRating() {
  const {
    user,
    userDB,
    datasetName,
    datasetPaths,
    datasetMarketplaceURLs,
    datasetLength,
    datasetCategory,
    jobName,
  } = useContext(MainPageContext);

  const [mrIndex, setMRIndex] = useState(0);
  const [folderNameState, setFolderNameState] = useState("");
  const [marketPlaceURLState, setMarketPlaceURLState] = useState("");
  const [imageNamesOnFolderState, setImageNamesOnFolderState] = useState([]);
  const [imagesOnMR, setImagesOnMR] = useState([]);
  const [complexityAnnotation, setComplexityAnnotation] = useState();

  const [isHovering, setIsHovering] = useState(false);
  const [guidelineImagesList, setGuidelineImagesList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let imageUrl;

      const imageNamesOnFolder = datasetPaths[mrIndex]?.images_in_folder;
      const folderName = datasetPaths[mrIndex]?.folder_name;

      if (datasetMarketplaceURLs){
        setMarketPlaceURLState(datasetMarketplaceURLs[folderName]);
      }
      

      setImageNamesOnFolderState(imageNamesOnFolder);
      setFolderNameState(folderName);

      if (imageNamesOnFolder) {
        let imagesURLs = [];
        for (const imageName of imageNamesOnFolder) {
          let imageURL = await getImagesUrlsFromMR(
            datasetName,
            folderName,
            imageName
          );
          imagesURLs.push(imageURL);
        }
        imageUrl = imagesURLs;
      }

      // TODO: add job to getComplexityAnnotation
      const annotation = await getAdminRatingAnnotations(folderName, user, jobName);
      if (annotation.length > 0) {
        setComplexityAnnotation(annotation[0].rating);
      } else {
        setComplexityAnnotation(null);
      }

      setImagesOnMR(imageUrl);
    };
    fetchData();
  }, [mrIndex, datasetCategory]);

  function increment() {
    if (mrIndex + 1 < datasetLength) {
      setMRIndex(mrIndex + 1);
    }
  }

  function decrement() {
    if (mrIndex > 0) {
      setMRIndex(mrIndex - 1);
    }
  }

  const context = {
    increment,
    decrement,
    datasetPaths,
    user,
    complexityAnnotation,
    setIsHovering,
    guidelineImagesList,
    setGuidelineImagesList,
    datasetCategory,
    imagesOnMR,
    folderNameState,
    imageNamesOnFolderState,
    mrIndex,
    jobName,
  };

  return (
    <PageContext.Provider value={context}>
      <ControlPanel
        userDB={userDB}
        datasetLength={datasetLength}
        mrIndex={mrIndex}
        setMRIndex={setMRIndex}
        marketPlaceURLState={marketPlaceURLState}
      />
      <div className="mt-[120px] top-[80px]">
        <div className="bg-adminRatingImagesBackground" >
          <ImagesList imagesOnMR={imagesOnMR} />
        </div>
      </div>
    </PageContext.Provider>
  );
}
