import { ImageCard } from "../ImageCard"

import "./styles.scss"

interface IImageGalleryProps {
  images: string[];
  imageNames: string[];
}

export function ImagesGallery({ images, imageNames }: IImageGalleryProps) {
  return (
    <div className="images-gallery-component">
      {images.map((image: string, idx: number) => (
        <ImageCard
          key={idx}
          imagePath={image}
          smallImageIndex={idx}
          imageFileName={imageNames[idx]}
        />
      ))}
    </ div>
  )
}