import { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import {
  sendAnnotations,
  getCategoriesDescriptions,
  getLastMainOrAusAnnotationIndex
} from "../../api";
import { Categories } from "../Categories/";
import cgtraderLogo from "./CGTrader.png";
import { ComplexityButtons } from "../ComplexityButtons";
import "./styles.scss";

export function ControlPanel({
  increment,
  decrement,
  setMRIndex,
  mrIndex,
  resetInitialState,
  datasetLength,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedComplexity,
  user,
  userDB,
  annotationObj,
  folderNameState,
  acceptOrRejectMarker,
  show,
  clearAllAnnotations,
  jobName
}: any) {
  const [categoriesDescriptions, setCategoriesDescriptions] = useState([]);
  const [lastAnnotationIndex, setLastAnnotationIndex] = useState();
  const [submitState, setSubmitState] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const lastIndex = await getLastMainOrAusAnnotationIndex(
        user,
        jobName
      );
      setLastAnnotationIndex(lastIndex);
    };
    fetchData();
  }, [mrIndex]);

  useEffect(() => {
    const fetchData = async () => {
      if (submitState) {
        const response = await sendAnnotations(
          user,
          folderNameState,
          mrIndex,
          selectedCategory,
          selectedSubCategory,
          annotationObj.MRAnnotations,
          selectedComplexity,
          submitState,
          jobName
        );

        if (response) {
          const lastIndex = await getLastMainOrAusAnnotationIndex(
            user,
            jobName
          );
          setSubmitState("");
          resetInitialState();
          setMRIndex(lastIndex);
        }
      }
    };
    fetchData();
  }, [submitState]);

  const fetchDescriptions = async () => {
    const categoriesDescription = await getCategoriesDescriptions();
    setCategoriesDescriptions(categoriesDescription);
  };

  useEffect(() => {
    fetchDescriptions();
  }, []);

  const categories = categoriesDescriptions.map((item: any) => {
    return {
      category: item.category,
      subcategories: item.subcategories.map(
        (subitem: any) => subitem.subcategory
      ),
    };
  });

  const subcategoriesDescription = categoriesDescriptions.map((item: any) => {
    return item.subcategories;
  });

  interface IStringIndex extends Record<string, any> { }

  const subcategories: IStringIndex = {};
  for (let index = 0; index < subcategoriesDescription.length; index++) {
    const element = subcategoriesDescription[index];

    for (const iterator of element) {
      subcategories[iterator.subcategory] = iterator.description;
    }
  }

  function SubmitOrRejectButton(props: any) {
    return (
      <>
        <button
          type="button"
          onClick={() => {
            setSubmitState("accept");
            // sendAnnotations(
            //   user,
            //   folderNameState,
            //   mrIndex,
            //   selectedCategory,
            //   selectedSubCategory,
            //   annotationObj.MRAnnotations,
            //   selectedComplexity,
            //   "accept",
            //   jobName
            // );
            // // increment();
            // goToLastAnnotationIndex();
          }}
          className="main-or-aux-control-panel-button submit-btn"
        >
          Submit
        </button>
      </>
    );
  }

  function DeactivatedSubmitOrRejectButton(props: any) {
    return (
      <>
        <button
          type="button"
          className="main-or-aux-control-panel-button deactivated-submit-btn"
          onClick={() => {
          }}
        >
          Submit
        </button>
      </>
    );
  }

  useEffect(() => {
    const setFromEvent = (e: any) => {
      if (e.key === "ArrowRight") {
        increment();
      } else if (e.key === "ArrowLeft") {
        decrement();
      }
    };
    window.addEventListener("keydown", setFromEvent);
    return () => {
      window.removeEventListener("keydown", setFromEvent);
    };
  }, [mrIndex, show]);

  function handleChange(e: any) {
    e.preventDefault();
    if (
      e.target[0].value > 0 &&
      e.target[0].value <= datasetLength &&
      parseInt(e.target[0].value) !== mrIndex
    ) {
      resetInitialState();
      setMRIndex(parseInt(e.target[0].value) - 1);
    }
  }

  function goToLastAnnotationIndex() {
    if (lastAnnotationIndex) {
      setMRIndex(lastAnnotationIndex);
    }
  }

  function clearAnnotations() {
    confirmAlert({
      message: "Are you sure you want to clear all annotations?",
      buttons: [
        {
          label: "Yes",
          onClick: () => clearAllAnnotations(),
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  }

  return (
    <>
      <div
        className={
          !acceptOrRejectMarker
            ? "control-panel-main-or-aux"
            : acceptOrRejectMarker === "accept"
              ? "control-panel-main-or-aux accepted"
              : "control-panel-main-or-aux rejected"
        }
      >
        <div className="control-panel-logo">
          <a href="/">
            <img src={cgtraderLogo} alt="cgtrader-logo" />
          </a>
        </div>

        <div className="main-or-aux-control-panel-indexes">
          <h1>{`${mrIndex + 1}/${datasetLength}`}</h1>

          <form
            onSubmit={(e) => {
              handleChange(e);
            }}
          >
            <input
              type="number"
              className="main-or-aux-control-panel-form-mr"
            />
          </form>
        </div>

        {userDB.type === "admin" ? (
          <div className="main-or-aux-control-panel-mr-indexes">
            <h1>MR: {folderNameState}</h1>
          </div>

        ) : (
          <></>
        )}

        <button
          type="button"
          onClick={() => {
            clearAnnotations();
          }}
          className="main-or-aux-control-panel-button clear-btn"
        >
          Clear
        </button>

        <div className="main-or-aux-control-painel-btns">
          <button
            type="button"
            onClick={() => {
              decrement();
            }}
            className="main-or-aux-control-panel-button navigation-btn"
          >
            Previous
          </button>

          {selectedCategory !== "" && selectedSubCategory !== "" ? (
            <SubmitOrRejectButton />
          ) : (
            <DeactivatedSubmitOrRejectButton />
          )}

          <button
            type="button"
            onClick={() => {
              increment();
            }}
            className="main-or-aux-control-panel-button navigation-btn"
          >
            Next
          </button>

          <button
            type="button"
            onClick={() => {
              goToLastAnnotationIndex();
            }}
            className="control-panel-button last-button"
          >
            Next to Annotate
          </button>


          <button
            type="button"
            onClick={() => {
              setSubmitState("reject");
              // sendAnnotations(
              //   user,
              //   folderNameState,
              //   mrIndex,
              //   selectedCategory,
              //   selectedSubCategory,
              //   annotationObj.MRAnnotations,
              //   selectedComplexity,
              //   "reject",
              //   jobName
              // );
              // increment();
            }}
            className="main-or-aux-control-panel-button flag-btn"
          >
            Flag
          </button>
        </div>

        <Categories
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
        />

        <ComplexityButtons
          // className="complexity-buttons"
          setMRIndex={setMRIndex}
        />
      </div>

      <div className="main-or-aux-category-description-container">
        <div className="main-or-aux-category-description">
          <div>
            {selectedSubCategory !== ""
              ? `${selectedSubCategory}: ${subcategories[selectedSubCategory]}`
              : null}
          </div>
        </div>
      </div>
    </>
  );
}
