import React, { useEffect, useState, useContext } from "react";
import { getDatasets, createDataset, updateDataset } from "./api";
import { NavBar } from "../../components/NavBar/NavBar";
import Modal from 'react-modal';


Modal.setAppElement('#root')

export function Datasets() {
  const [datasets, setDatasets] = useState([]);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });

  const [datasetName, setDatasetName] = useState(null);
  const [datasetDescription, setDatasetDescription] = useState(null);
  const [saved, setSaved] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalOpen = () => {
    setIsModalOpen(true);
    setInEditMode({
      status: true,
      rowKey: null,
    });
    setDatasetName("");
    setDatasetDescription("");
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    onCancel();
  };

  const onModalSave = () => {
    onSave(null, datasetName, datasetDescription);
    setIsModalOpen(false);
  };


  const fetchData = async () => {
    const datasetsResponse = await getDatasets(currentPage, limit);

    if (datasetsResponse) {
      setDatasets(datasetsResponse.datasets);
      setTotalPages(datasetsResponse.totalPages);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, saved]);

  const data = React.useMemo(() =>
    datasets.map((dataset) => {
      return {
        _id: dataset?._id,
        datasetName: dataset?.dataset_name,
        numberOfImages: dataset?.number_of_images,
        datasetDescription: dataset?.dataset_description,
      };
    })
  );

  const onEdit = ({ _id, currentDatasetName, currentDatasetDescription }) => {
    setInEditMode({
      status: true,
      rowKey: _id,
    });
    setDatasetName(currentDatasetName);
    setDatasetDescription(currentDatasetDescription);
  };

  const onSave = (id, newDatasetName, newDatasetDescription) => {
    if (id) {
      updateDataset(id, newDatasetDescription);
    } else {
      createDataset(newDatasetName, newDatasetDescription);
    }
    fetchData();
    setSaved("saved");
    onCancel();
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setSaved(null);
  };

  return (
    <>
      <NavBar />
      <div className="flex flex-col items-center justify-center mt-20 w-full max-w-full grid ">
        <button
          className="mx-auto my-5 px-4 py-2 bg-blue-500 text-white rounded-md"
          onClick={onModalOpen}
        >
          Create Dataset
        </button>
        <table className="self-center text-center border-transparent">
          <thead>
            <tr>
              <th className="px-2 py-3 text-center bg-blue-600 text-white">Dataset Name</th>
              <th className="px-2 py-3 text-center bg-blue-600 text-white">Dataset Description</th>
              <th className="px-2 py-3 text-center bg-blue-600 text-white">Number of Items</th>
              <th className="px-2 py-3 text-center bg-blue-600 text-white">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item._id} className={`${index % 2 === 0 ? 'bg-gray-200' : 'bg-white'} hover:bg-gray-300`}>
                <td className="p-3">
                  {inEditMode.status && inEditMode.rowKey === item._id ? (
                    <input
                      value={datasetName}
                      onChange={(event) => setDatasetName(event.target.value)}
                    />
                  ) : (
                    item.datasetName
                  )}
                </td>
                <td>
                  {inEditMode.status && inEditMode.rowKey === item._id ? (
                    <input
                      value={datasetDescription}
                      onChange={(event) =>
                        setDatasetDescription(event.target.value)
                      }
                    />
                  ) : (
                    item.datasetDescription
                  )}
                </td>
                <td>{item.numberOfImages}</td>

                <td>
                  {inEditMode.status && inEditMode.rowKey === item._id ? (
                    <React.Fragment>
                      <button
                        onClick={() =>
                          onSave(item._id, datasetName, datasetDescription)
                        }
                      >
                        Save
                      </button>
                      <button
                        style={{ marginLeft: 8 }}
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    </React.Fragment>
                  ) : (
                    <button
                      onClick={() =>
                        onEdit({
                          _id: item._id,
                          currentDatasetName: item.datasetName,
                          currentDatasetDescription: item.datasetDescription,
                        })
                      }
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center my-5">
          <button
            className={`px-4 py-2 bg-blue-500 text-white rounded-md ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
          >
            Previous
          </button>
          <span className="mx-4 text-2xl mb-4 font-bold text-white">Page {currentPage} of {totalPages}</span>
          <button
            className={`px-4 py-2 bg-blue-500 text-white rounded-md ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`}
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((page) => Math.min(page + 1, totalPages))}
          >
            Next
          </button>

        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={onModalClose}
          contentLabel="Create Dataset"
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 p-5 bg-white rounded shadow-lg outline-none"
          overlayClassName="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50"
        >
          <h2 className="text-2xl mb-4 text-center">Create Dataset</h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Dataset Name
            </label>
            <input
              value={datasetName}
              onChange={(event) => setDatasetName(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Dataset Description
            </label>
            <input
              value={datasetDescription}
              onChange={(event) => setDatasetDescription(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-300 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={onModalSave}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save
            </button>
            <button
              onClick={onModalClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}
