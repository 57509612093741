const axios = require("axios").default;

const API_URL = process.env.REACT_APP_SERVER_URL;

export const getLastComplexityAnnotationIndex = async (
  user: any,
  selectedCategory: string,
  job: string
) => {
  try {
    const response = await axios.get(
      `${API_URL}/complexity-last-index/?user_email=${user.email}&category=${selectedCategory}&job=${job}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const sendComplexityAnnotations = async (
  complexity: number,
  user: any,
  category: string,
  mrIndex: number,
  uuid: string,
  imageName: string,
  job: string
) => {
  try {
    const userName = user.nickname;
    const userEmail = user.email;

    const payload = {
      _id: `${uuid}-${userName}`,
      user_name: userName,
      user_email: userEmail,
      image_index: mrIndex,
      uuid: uuid,
      image_name: imageName,
      complexity,
      category,
      job,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/complexity-annotations/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getDatasetJson = async (dataset: string) => {
  try {
    const response = await axios.get(`${API_URL}/get-dataset-json`, {
      params: {
        dataset,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getUser = async (user: any) => {
  try {
    if (user) {
      const response = await fetch(`${API_URL}/get-user?user=${user.email}`);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson;
      }
      return {};
    }
  } catch (err) {
    console.log(err);
  }
};

export const getJob = async (jobName: string) => {
  try {
    const response = await axios.get(`${API_URL}/get-job`, {
      params: {
        job_name: jobName,
      },
    });
    if (response.status === 200) {
      return response.data;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const getImagesUrlsFromMR = async (
  dataset: string,
  folder: string,
  image_name: string
) => {
  try {
    const response = await axios.get(`${API_URL}/get-image-url`, {
      params: {
        dataset,
        folder,
        image_name,
      },
    });

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getComplexityAnnotations = async (
  modelingRequestId: string,
  user: any
) => {
  try {
    const userName = user.nickname;
    const response = await axios.get(
      `${API_URL}/complexity-annotations/?_id=${modelingRequestId}&user_name=${userName}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
