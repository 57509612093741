import { NavLink } from "react-router-dom";
import React from "react";
import "./MainNav.css";

const MainNav = (props) => {
  const { user } = props;
  return (
    <div className="navbar-nav mr-auto">
      {/* <NavLink
        to="/"
        exact
        className="nav-link"
        activeClassName="router-link-exact-active"
      >
        Home
      </NavLink> */}
      <NavLink
        to="/"
        exact
        className="nav-link"
        activeClassName="router-link-exact-active"
      >
        Applications
      </NavLink>
      {user.type === "admin" ? (
        <>
          <NavLink
            to="/users"
            exact
            className="nav-link"
            activeClassName="router-link-exact-active"
          >
            Users
          </NavLink>

          <NavLink
            to="/jobs"
            exact
            className="nav-link"
            activeClassName="router-link-exact-active"
          >
            Jobs
          </NavLink>

          <NavLink
            to="/datasets"
            exact
            className="nav-link"
            activeClassName="router-link-exact-active"
          >
            Datasets
          </NavLink>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default MainNav;
