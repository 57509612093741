import React, { useState } from 'react';


const UsersTable = ({ data, setModalUserType, setModalUserJob, setModalUserId, setModalUserEmail }) => {

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const onRowClick = async (index, userId, userJob, userType, userEmail) => {
    setSelectedRowIndex(index);
    setModalUserId(userId);
    setModalUserJob(userJob);
    setModalUserType(userType);
    setModalUserEmail(userEmail);
  };

  return (
    <div className="flex justify-center ">
      <table className=" bg-white inline-block">
        <thead>
          <tr className="w-full h-16 border-gray-300 border-b py-8 bg-blue-500">
            <th className="text-center text-white font-bold  ">User Email</th>
            <th className="text-center text-white font-bold  ">User Name</th>
            <th className="text-center text-white font-bold  ">User Job</th>
            <th className="text-center text-white font-bold px-2 ">User Type</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user, index) => (
            <tr
              key={index}
              className={`h-10 border-gray-300 border-b hover:bg-gray-300 ${index === selectedRowIndex && 'bg-blue-300 text-white'}`}
              onClick={() => {onRowClick(index, user._id, user.job, user.type, user.user_email) }}
            >
              <td className="px-2 text-center text-sm  text-gray-800 ">{user.user_email}</td>
              <td className="px-2 text-center text-sm  text-gray-800 ">{user.user_name}</td>
              <td className="px-2 text-center text-sm  text-gray-800 ">{user.job}</td>
              <td className="px-2 text-center text-sm  text-gray-800 ">{user.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UsersTable;