import { useContext, useState, useEffect } from "react";
import PageContext from "../StatesManager";
import "./styles.scss";
import cgtraderLogo from "./CGTrader.png";
import {
  getLastComplexityAnnotationIndex,
  sendComplexityAnnotations,
} from "../../api";

import ControlPanelComplexityContext from "./ControlPanelComplexityContext";
import { ComplexityButtons } from "../ComplexityButtons";

export function ControlPanel({ datasetLength, mrIndex, setMRIndex, userDB, marketPlaceURLState }) {
  const {
    user,
    increment,
    decrement,
    show,
    datasetCategory,
    folderNameState,
    imageNamesOnFolderState,
    datasetPaths,
    jobName,
  } = useContext(PageContext);

  function sendAnnotations(complexity) {
    sendComplexityAnnotations(
      complexity,
      user,
      datasetCategory,
      mrIndex,
      folderNameState,
      imageNamesOnFolderState[0],
      jobName
    );
    increment();
  }

  const [lastAnnotationIndex, setLastAnnotationIndex] = useState();
  const [annotationSent, setAnnotationSent] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const lastIndex = await getLastComplexityAnnotationIndex(
        user,
        datasetCategory,
        jobName
      );
      setLastAnnotationIndex(lastIndex);
    };
    fetchData();
  }, [mrIndex, datasetCategory]);

  useEffect(() => {
    const fetchData = async () => {
      const lastIndex = await getLastComplexityAnnotationIndex(
        user,
        datasetCategory,
        jobName
      );
      setMRIndex(lastIndex);
    };
    fetchData();
  }, [annotationSent]);

  useEffect(() => {
    const setFromEvent = (e) => {
      if (e.key === "ArrowRight") {
        increment();
      } else if (e.key === "ArrowLeft") {
        decrement();
      }
    };
    window.addEventListener("keydown", setFromEvent);
    return () => {
      window.removeEventListener("keydown", setFromEvent);
    };
  }, [mrIndex, show]);

  function goToLastAnnotationIndex() {
    if (lastAnnotationIndex !== null) {
      setMRIndex(lastAnnotationIndex);
    }
  }

  function changeMR(e) {
    e.preventDefault();
    if (
      e.target[0].value <= datasetLength &&
      e.target[0].value > 0 &&
      parseInt(e.target[0].value) !== mrIndex
    ) {
      setMRIndex(parseInt(e.target[0].value) - 1);
    }
  }

  function changeMrID(e) {
    e.preventDefault();
    let indexOfMR = "1";
    for (let index = 0; index < datasetPaths.length; index++) {
      const element = datasetPaths[index];
      if (element.folder_name == e.target[0].value) indexOfMR = index;
    }
    setMRIndex(parseInt(indexOfMR));
  }

  const handleMarketplaceClick = () => {
    window.open(marketPlaceURLState, '_blank');
  };


  const context = {};

  return (
    <ControlPanelComplexityContext.Provider value={context}>
      <div className="complexity-control-panel-container" id="control-panel">
        <div className="complexity-control-panel">
          <div className="logo">
            <a href="/cglabel">
              <img src={cgtraderLogo} alt="cgtrader-logo" />
            </a>
          </div>

          <button
            type="button"
            onClick={() => {
              decrement();
            }}
            className="bg-controlPanelBackground control-panel-button previous-button"
          >
            Previous
          </button>

          <button
            type="button"
            onClick={() => {
              increment();
            }}
            className="bg-controlPanelBackground control-panel-button next-button"
          >
            Next
          </button>

          <button
            type="button"
            onClick={() => {
              goToLastAnnotationIndex();
            }}
            className="bg-controlPanelBackground control-panel-button last-button"
          >
            Next to Annotate
          </button>

          <button
            type="button"
            onClick={() => sendAnnotations("9999")}
            className="bg-controlPanelBackground control-panel-button delete-button"
          >
            Flag
          </button>

          <button
            type="button"
            onClick={handleMarketplaceClick}
            className="bg-controlPanelBackground submit-button control-panel-button "
          >
            Marketplace URL
          </button>


          <div>
            <h1>{`${mrIndex + 1}/${datasetLength}`}</h1>

            {userDB.type === "admin" ? (
              <div className="main-or-aux-control-panel-mr-indexes">
                <h1>MR: {folderNameState}</h1>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div>
            <form
              onSubmit={(e) => {
                changeMR(e);
              }}
            >
              <input type="number" className="form-mr" />
            </form>

            {userDB.type === "admin" ? (
              <form
                onSubmit={(e) => {
                  changeMrID(e);
                }}
              >
                <input type="number" className="form-mr" />
              </form>
            ) : (
              <></>
            )}
          </div>

          <ComplexityButtons
            className="complexity-buttons"
            goToLastAnnotationIndex={goToLastAnnotationIndex}
            setAnnotationSent={setAnnotationSent}
            annotationSent={annotationSent}
            setMRIndex={setMRIndex}
          />
        </div>
      </div>
    </ControlPanelComplexityContext.Provider>
  );
}
