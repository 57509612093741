const axios = require("axios").default;

const API_URL = process.env.REACT_APP_SERVER_URL;


export const getJobs = async (page = 1, limit = 10) => {
  try {
    const response = await fetch(`${API_URL}/get-jobs?page=${page}&limit=${limit}`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const createJob = async (
  newJobName,
  newJobType,
  newJobDataset,
  newMrInitialIndex,
  newMrFinalIndex
) => {
  try {
    const payload = {
      job_name: newJobName,
      job_type: newJobType,
      job_dataset: newJobDataset,
      initial_index: newMrInitialIndex,
      final_index: newMrFinalIndex,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/create-job/`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateJob = async (
  id,
  newJobName,
  newJobType,
  newJobDataset,
  newMrInitialIndex,
  newMrFinalIndex,
  optionSelected
) => {
  try {
    const payload = {
      _id: id,
      job_name: newJobName,
      job_type: newJobType[0].label,
      job_dataset: optionSelected[0].label,
      initial_index: newMrInitialIndex,
      final_index: newMrFinalIndex,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.patch(
      `${API_URL}/update-job`,
      payload,
      config
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const deleteJob = async (_id) => {
  try {
    const payload = {
      _id,
    };
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    const response = await axios.delete(`${API_URL}/delete-job`, {
      data: payload,
    });

    return response;
  } catch (err) {
    console.log(err);
  }
};

// export const getDatasets = async () => {
//   try {
//     const response = await fetch(`${API_URL}/get-datasets`);
//     if (response.ok) {
//       const responseJson = await response.json();
//       return responseJson;
//     }
//     return {};
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getDatasets = async (page = 1, limit = 100) => {
  try {
    const response = await fetch(`${API_URL}/get-datasets?page=${page}&limit=${limit}`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const getUsers = async () => {
  try {
    const response = await fetch(`${API_URL}/get-users`);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};

export const userJobStatus = async (userNames, jobType, jobName) => {
  try {
    const payload = {
      user_names: userNames,
      job_name: jobName,
      job_type: jobType,
    };

    const config = {
      headers: {
        Accept: "application/json",
      },
    };

    const response = await axios.post(
      `${API_URL}/job-status/`,
      payload,
      config
    );

    if (response.status === 200) {
      return response.data;
    }
    return {};
  } catch (err) {
    console.log(err);
  }
};


export const getComplexityAnnotationsUsers = async (
  jobName
) => {
  try {
    const response = await axios.get(
      `${API_URL}/complexity-annotations-users/?job_name=${jobName}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};


export const getAdminRatingAnnotationsUsers = async (
  jobName
) => {
  try {
    const response = await axios.get(
      `${API_URL}/admin-rating-annotations-users/?job=${jobName}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};